import { ref } from "vue";
import { db, auth } from "../firebase/config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
const updateSeen = async (cor, seen) => {
  const seenVid = ref(null);
  const user = auth.currentUser.email;
  if (seen) {
    try {
      // const res = await db.collection("onco").doc(user).get()
      const res = await getDoc(doc(db, "onco", user));
      if (res.exists) {
        seenVid.value = res.data().course[`${cor}`];
      }
      seenVid.value = [...seenVid.value, seen];
      // await db.collection("onco").doc(user).update(`course.${cor}`, seenVid.value)
      await updateDoc(doc(db, "onco", user), `course.${cor}`, seenVid.value);
    } catch (err1) {
      console.log(err1);
    }
  }
  return { seenVid };
};
export default updateSeen;
